.navbar {
  height: 50px;
  border-bottom: 0.5px solid rgb(230, 227, 227);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  width: 100%;
}

.search {
  display: flex;
  align-items: center;
  border: 0.5px solid lightgray;
  padding: 3px;
  border-radius: 6px;
}
input {
  border: none;
  outline: none;
  background-color: transparent;
}

.items {
  display: flex;
  align-items: center;
}

.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
}

.icon {
  font-size: 20px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.counter {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
}
