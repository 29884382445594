.sidebar {
  flex: 1;
  border-right: 0.5px solid rgb(230, 227, 227);
  min-height: 100vh;
  background-color: white;
}

.top {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgb(230, 227, 227);
}

.logo {
  font-size: 20px;
  font-weight: bold;
  color: rgb(99, 98, 98);
}

hr {
  height: 0;
  border: 0.5px solid rgb(230, 227, 227);
}

.center {
  padding-left: 10px;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 5px;
  margin-top: 10px;
  cursor: pointer;
}

li:hover {
  background-color: lightgray;
}

.icon {
  font-size: 18px;
  color: gray;
}

span {
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
}
