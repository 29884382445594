* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

/* Media Queries */
@media screen and (max-width: 280px) {
  html {
    overflow-x: hidden;
  }
  .sidebar {
    display: none;
  }
}
