.dashboard {
  display: flex;
}

.homeContainer {
  flex: 6;
}

.widgets,
.charts {
  display: flex;
  padding: 20px;
  gap: 20px;
}
.charts {
  padding: 5px 20px;
}

.listContainer {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 6px;
  padding: 20px;
  margin: 20px;
}

.listTitle {
  color: rgb(104, 102, 102);
  font-weight: 600;
  margin-bottom: 15px;
}
