.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 10px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 6px;
  height: 100px;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right {
  display: flex;
  flex-direction: column-reverse;
}

.title {
  font-weight: bold;
  font-size: 16px;
  color: rgb(104, 102, 102);
  /* border-bottom: 0.5px solid rgb(230, 227, 227); */
}

.number {
  font-size: 14px;
  font-weight: 300;
}

.percentage {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.positive {
  color: green;
}

.negative {
  color: red;
}
